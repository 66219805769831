.sticky-header th {
  position: sticky !important;
  top: 10 !important;
  z-index: 1 !important;
  background-color: #f8f9fa !important;
}
.fc-event-time {
  display: none;
}

.small-table {
  font-size: 0.8rem !important;
}
.small-table > thead tr th {
  position: static !important;
  top: 18px !important;
}
.small-table th,
.small-table td {
  font-size: 0.8rem !important;
  padding: 0.2rem !important;
}

.dash-select .css-1xc3v61-indicatorContainer {
  padding: 0 !important;
}

.bg-b-green {
  background: linear-gradient(45deg, #2ed8b6, #59e0c5);
}
.bg-b-yellow {
  background: linear-gradient(45deg, #ffb64d, #ffcb80);
}
.bg-b-pink {
  background: linear-gradient(45deg, #ff5370, #ff869a);
}
.custom-carousel-control {
  color: #59e0c5 !important;
  display: none;
}
.visually-hidden {
  display: none !important;
}
.bg-b-holiday {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}
.TimeSize {
  min-width: max-content;
}

/* 
@media only screen and (max-width: 767px) {
  .Select-1 .css-1wa3eu0-placeholder,
  .Select-1 .css-1uccc91-singleValue {
    font-size: 14px;
  }

  .Select-1 .css-26l3qy-menu {
    font-size: 14px;
  }

  .Select-1 .css-1emnig8-control {
    min-width: 14rem; 
  }
} */

.salary-slip-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.salary-slip-table td,
.salary-slip-table th {
  border-bottom: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.salary-slip-table tr:first-child td {
  border-top: none;
}

.salary-slip-table tr:last-child td {
  border-bottom: none;
}

.salary-slip-table .label {
  font-weight: bold;
  white-space: nowrap;
}

.salary-slip-table .value {
  padding-left: 5px;
  border-right: 2px solid black;
  text-align: right;
}
.salary-slip-table .NewValues {
  text-align: right;
}

.table-custom {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.table-custom td,
.table-custom th {
  border-bottom: 1px solid #000;
  padding: 8px;
  text-align: left;
}

.table-custom .label {
  font-weight: bold;
  white-space: nowrap;
}

.table-custom .value {
  padding-left: 5px;
  text-align: right;
}

.table-custom tr:first-child td {
  border-top: none;
}

/* costome1 */

.customTable1 {
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
}

.customTable1 td,
.customTable1 th {
  /* border-bottom: 1px solid #000; */
  padding: 8px;
  text-align: left;
}

.customTable1 .label {
  font-weight: bold;
  white-space: nowrap;
}

.customTable1 .value {
  padding-right: 15px;
  text-align: right;
}

.customTable1 tr:first-child td {
  border-top: none;
}

.givenStyles {
  font-size: "13px";
  padding-top: "3px";
  padding-bottom: "3px";
}

@media only screen and (max-width: 576px) {
  .Select-1 .css-1emnig8-control {
    min-width: 10rem;
  }
  .css-1emnig8-control {
    min-width: 10rem;
  }
  .Select-1.css-1p7v661-control {
    width: 15px;
  }
  .css-qbdosj-Input {
    min-width: 15px;
  }
  #react-select-2-listbox {
    min-width: 10rem;
  }
  .css-3355qi-menu {
    min-width: 10px;
    font-size: 14px;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 400px) {
  .newOrder {
    order: 12;
  }
}
.givenWigth {
  width: 75%;
}
@media only screen and (max-width: 1024px) {
  .givenWigth {
    width: 100%;
  }
}
.forRight {
  justify-content: space-between;
}

@media only screen and (max-width: 992px) {
  .forRight {
    justify-content: center;
  }
}
.Modal-Height {
  height: 50%;
  overflow: hidden;
}
.newCustomFontSize {
  font-size: 13px !important;
}
.crop_img {
  border-radius: 50%;
  width: 9rem;
  height: 9rem;
}
.image-remove {
  color: #dc3545;
  font-size: 1.2rem;
  cursor: pointer;
  z-index: 888;
}
.translate-middle {
  transform: translate(-50%, -50%);
}

.error-message {
  font-size: 0.7rem;
  color: #dc3545;
  padding-top: 0.1rem;
}
.border-color {
  border: 1px solid #dc3545 !important;
  border-radius: 0.3rem;
}
.my-cursor {
  background-color: #42a5f5 !important;
  color: #fff;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.selected-class {
  background-color: #42a5f5;
  color: #fff;
}
.checkbox-cursor {
  cursor: pointer;
}
.custom-switch:hover {
  cursor: pointer !important;
}
.info {
  background-color: #e7f3fe;
  border-left: 6px solid #2196f3;
}
.clickable-row {
  cursor: pointer;
}
.clickable-row-color {
  cursor: pointer;
}

.clickable-row-color:hover {
  background-color: #999 !important;
  color: #000;
}
.obj_bolder {
  font-size: 0.9rem;
  font-weight: bold;
}

.table-hover tbody tr:hover {
  background-color: #17a2b8;
}
.mobel_box {
  max-width: 95% !important;
}
.mobel_box1 {
  max-width: 55% !important;
}
.mobel_box_Grade {
  min-width: 75% !important ;
}
@media (max-width: 576px) {
  .mobel_box_Grade {
    max-width: 90%;
  }
}

.mobel_box2 {
  /* max-width: 55% !important; */
}

@media (max-width: 900px) {
  .mobel_box1 {
    max-width: 80%;
  }
}

/* Adjustments for smaller screens */
@media (max-width: 576px) {
  .mobel_box1 {
    max-width: 100%;
  }
}

.hide_input {
  display: none;
}
.nav-link {
  border-bottom: none !important;
  /* font-size: 20rem !important; */
  padding: 0.5rem 0 !important;
}

.progress-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.progress-center {
  display: inline-block;
  text-align: center;
}
.loading {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: grid;
  place-items: center;
  max-width: 100%;
}
.orcircle {
  text-align: center;
  color: #fff;
  font-weight: 900;
  font-size: 1.3rem;
}
.orcircle > span {
  border-radius: 0.5rem;
  background-color: #17a2b8;
  padding: 0.2rem;
}
.selected-row {
  background-color: #17a2b8;
  color: white;
}
.cl_pointer {
  cursor: pointer;
  font-size: 0.5rem;
}

.loading > div {
  background-color: rgba(255, 255, 255, 0.8);
  background-image: url("./assets/image/customLoader1.gif");
  background-size: contain;
  width: calc(15vw - 20px);
  height: calc(20vh - 10px);
  background-position: center;
  background-repeat: no-repeat;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 800px) {
  .loading > div {
    width: calc(30vw - 30px);
    height: calc(30vh - 20px);
  }
}
@media (min-width: 800px) and (max-width: 1280px) {
  .loading > div {
    width: calc(15vw - 25px);
    height: calc(15vh - 15px);
  }
}
/* .loading > div {
  background: rgba(0, 0, 0, 0) url('./assets/image/customLoader1.gif') no-repeat center/cover;
  width: 10%;
  height: 20%;
} */
@keyframes loadingRotate {
  to {
    transform: rotateZ(-360deg);
  }
}

tr.hide-table-padding td {
  padding: 0;
}

.expand-button {
  position: relative;
}

.accordion-toggle .expand-button:after {
  position: absolute;
  left: 0.75rem;
  top: 50%;
  transform: translate(0, -50%);
  content: "-";
}
.accordion-toggle.collapsed .expand-button:after {
  content: "+";
}

/* ------------ */

.i_cloud {
  color: green;
  font-size: 2rem;
  cursor: pointer;
}
/* hide input number bar  */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .no-list-message {
  font-size: 16px;
  color: #999;
  text-align: center;
  margin: 20px 0;
} */

.empty-list-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  /* background-color: #f7f7f7; */
  border-radius: 5px;
  /* border: 1px solid #ddd; */
}

.no-list-message {
  font-size: 2rem;
  color: #999;
  font-family: Georgia, "Times New Roman", Times, serif;
  text-align: center;
  margin-bottom: 10px;
  font-style: italic;
}

.grade-card-msg {
  font-family: Georgia, "Times New Roman", Times, serif;
  color: red;
}
.login_font {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 2rem !important;
  text-transform: uppercase;
  padding-top: 1rem;
  margin-bottom: 0;
  /* border-bottom: 2px dashed #0E487A; */
}

.empty-icon {
  font-size: 48px;
  color: #ccc;
}

.rotating-img {
  animation: rotate 10s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.popup-message {
  position: absolute;
  background-color: #ffffff; /* Bootstrap's danger color */
  color: #f10018; /* Bootstrap's dark red color */
  padding: 5px 5px;
  z-index: 1;
  top: 100%;
  left: 0;
  width: auto;
  min-width: 200px;
  text-align: left;
  border-color: #000;
  border-style: solid;
  border-width: 0 1px 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 10px;
}

.popup-message .fa {
  margin-right: 5px;
}

.popup-message::after,
.popup-message::before {
  border-color: inherit;
  border-style: inherit;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: -10px;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}
.popup-message::after {
  border-width: 0 1px 1px 0;
  left: -3px;
  -webkit-transform: skewX(135deg);
  transform: skewX(135deg);
  width: calc(30% - 6px);
}
.popup-message::before {
  border-width: 0 0 1px 1px;
  right: -2px;
  position: absolute;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  width: calc(70% - 6px);
}

.popup-message-right {
  position: absolute;
  background-color: #ffffff;
  color: #f10018; /* Bootstrap's dark red color */
  padding: 5px 5px;
  z-index: 1;
  top: 100%;
  right: 0;
  width: auto;
  min-width: 200px;
  text-align: left;
  border-color: #000;
  border-style: solid;
  border-width: 0 2px 2px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 10px;
}

.popup-message-right .fa {
  margin-right: 5px;
}

.popup-message-right::after,
.popup-message-right::before {
  border-color: inherit;
  border-style: inherit;
  content: "";
  display: block;
  height: 10px;
  position: absolute;
  top: -10px;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
}
.popup-message-right::after {
  border-width: 0 2px 2px 0;
  left: -3px;
  -webkit-transform: skewX(135deg);
  transform: skewX(135deg);
  width: calc(70% - 6px);
}
.popup-message-right::before {
  border-width: 0 0 2px 2px;
  right: -2px;
  position: absolute;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  width: calc(30% - 6px);
}

.questionlist {
  box-shadow: 5px 5px #e9ecef;
  border: 1px solid #e9ecef;
}

.questionlist .fa {
  margin-top: 10px;
  cursor: pointer !important;
}

.card-title {
  margin-bottom: 10px !important;
  font-weight: bold !important;
}

.custom-circle {
  border: 2px solid #ffc107;
  border-radius: 50%;
  background-color: transparent;
}

.my-table tr th {
  text-transform: capitalize;
  /* background-color: #E0F4FF; */
  color: #42a5f5;
  text-wrap: nowrap;
}

.my-table tr th::after {
  content: ":";
  margin-left: 0.5rem;
  color: #000;
}

.scale-btn > i,
.scale-btn > span,
.scale-btn {
  transition: transform 0.3s ease;
}

.scale-btn:hover {
  transform: scale(1.1);
}

.scale-btn:hover i,
.scale-btn:hover span {
  transform: scale(1.1);
}

.switch-label {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 0.1rem;
}

.switch-text {
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.checkbox {
  font-size: 1rem;
  /* min-height: 320px; */
  box-sizing: content-box;
  padding: 0.1rem;
}
.cast-button {
  margin-left: 1.25rem;
}
.checkbox:hover {
  background: rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.isCategory-selected {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-20px);
  }
  60% {
    transform: translateY(-10px);
  }
}

.animated {
  animation: bounce 1s infinite;
}
.bed-style {
  border: 1px solid gray;
}

.card-hover {
  transition: transform 0.3s ease;
}
.card-hover:hover {
  transform: scale(1.1);
}

/* login  */

.resp-height {
  height: 100vh !important;
}
.comp-name {
  display: flex;
  justify-content: start !important;
  margin-left: 8%;
}

@media only screen and (max-width: 1399px) and (min-width: 1200px) {
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
}

@media only screen and (max-width: 991px) {
}

@media only screen and (max-width: 768px) {
  .resp-height {
    height: auto !important;
  }
  .box-margin {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .comp-name {
    display: flex;
    justify-content: center !important;
    margin-left: 0%;
  }
}
@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 499px) {
}

@media only screen and (max-width: 425px) {
}

@media only screen and (max-width: 389px) {
}

@media only screen and (max-width: 370px) {
}

.scrollable-days-container {
  max-width: 100px; /* Set a fixed width for the container */
  overflow-x: auto; /* Enable horizontal scrolling */
}

.days-wrapper {
  display: flex;
}

.day-cell {
  min-width: 40px; /* Set custom width for each day cell */
  flex-shrink: 0; /* Prevent day cells from shrinking */
}

.custom1_modal1 {
  max-width: 65% !important;
}

@media only screen and (max-width: 700px) {
  .custom1_modal1 {
    max-width: 100% !important;
  }
}

.ColorsForWOF {
  background-color: #bababa;
}

.colorForHalfDay {
  background-color: #fffab0;
}

/* File: SalarySlip.css */
.Wholeborder {
  border: 2px solid black;
  padding: 10px;
}
.Boder {
  border-bottom: 2px solid black;
}

.Boder1 {
  padding: 0px;
  margin-top: -2px;
  border-bottom: 3px solid black;
}

.rlborder {
  border-right: 2px solid black;
  border-left: 2px solid black;
  border-bottom: 2px solid black;
}

.Bleft {
  border-left: 2px solid black;
}

.Gappp {
  margin-left: 8%;
}
.givenStaticWidth {
  width: 400px;
}

.Extra-Dotted {
  border-bottom: 1px dotted black;
}

.Bright .table td,
.Bright .table th {
  border-right: 5px solid black; /* Change the border style as needed */
}

.Merging {
  margin: 30px;
  border-collapse: collapse;
}

.scrollable-container {
  max-height: 100vh; /* Adjust this value as needed */
  overflow-y: auto;
  padding: 10px; /* Optional: adds some padding inside the scrollable area */
}

.sizeforInput {
  max-width: 100px;
}
