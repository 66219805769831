
.remove-button {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  cursor: pointer;
  opacity: 0; 
  transition: opacity 1.3s ease; /* smooth transition on hover */
}

.image-container:hover .remove-button {
  opacity: 1; 
}

.uploaded-images-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.image-container {
  position: relative;
  margin-left: 10px;
}

.uploaded-image {
  max-width: 200px;
  max-height: 200px;
}

.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  font-size: 18px;
  border-radius: 50%;
  display: none;
}

.image-container:hover .delete-icon {
  display: block;
}

#introductionquill .ql-container{
  height: 130px;
  overflow: auto;

}
#introductionquill1 .ql-container{
  height: 150px;
  overflow: auto;

}



.image-container {
  /* position: relative;
  display: inline-block; */
}

.uploaded-image {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: transform 0.2s ease;
}

.remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.remove-button:hover {
  background-color: rgba(255, 168, 168, 0.8);
}
