/* body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
  } */
  
  .container {
    /* max-width: 600px; */
    margin: 0 auto;
    /* padding: 20px; */
    /* border: 1px solid #000 */
  }
  
  .school-info {
    text-align: center;
    line-height: 1.9;
  }
  
  .bottom-line{
    border-bottom: double;
  }
  
  .address {
    font-size: 16px;
    margin-top: 10px;
    border-bottom: double;
  }
  
  .certificate {
    margin-top: 30px;
    /* border: 1px solid #000; */
    padding: 20px;
  }
  
  .certificate-header {
    text-align: center;
    font-size: 1rem !important;
    font-weight: bold;
    font-family: "Times New Roman", Times, serif;
   
  }

 #model-con  .container{
    font-size: 12px !important;
    padding: 10px !important;
  }

  .blank_line{
    border-bottom: double;
    display: inline;
  }

  .border-tc-values{
    border-bottom: 1px dotted black; 
    font-weight:500 !important;
    min-height: 20px !important;
  }

  .admission-no {
    border-bottom: 1px dotted black; 
    font-weight:500 !important;
  }
  .no-marker {
    list-style-type: none;
  }
  .col p{
    font-weight:700;
    line-height: 1.1;
  }
  .tc-order > li{
    font-weight:700;
    line-height: 1.2;
    margin-left: -1rem;
  }
  .serial-no,
  .gr-no {
    font-weight: bold;
  }
  
  .student-info {
    margin-top: 20px;
  }
  
  .print-footer {
    margin-top: 40px;
    text-align: right;
  }
  
  .date,
  .signature {
    font-weight: bold;
  }
  
  .signature {
    margin-top: 1rem;
  }
  .after_page_brack{
    page-break-after: always !important;
  }