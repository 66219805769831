.slide-container {
  width: 100%;
  overflow: hidden;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image-wrapper {
  flex: 1;
  margin: 10px;
}

.slide-image {
  width: 300px;
  height: 180px;
  border-radius: 10px;
} 

@media screen and (max-width: 533px) {
  .slide-image {
    width: 200px;
    height: 100px;
  }
}

/* Media query for mobile devices */
@media screen and (max-width: 400px) {
  .slide-image {
    width: 100px;
    height: 60px;
  }
}
