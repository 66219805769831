body {
  background: #000;
}



.button-box {

  width: 200px;
  /* margin: 35px auto; */
  position: relative;
  border-radius: 25px;
  background: #ccd5dd;
  display: flex;
  justify-content: space-between;
}

.toggle-btn {
  flex: 1;
  padding: 10px 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  outline: none;
  text-align: center;
  position: relative;
  z-index: 1;
}

#btn {
  position: absolute;
  left: 0;
  width: 50%;
  height: 100%;
  background: #e67e22;
  border-radius: 30px;
  transition: left 0.4s ease;  
  z-index: 0;

}

#btn.right {
  left: 50%;
}

button:focus {
  outline:none !important;
}
