.ehed{
    background-color: #c4e5e4;

}
.Total{
     background-color: #d6eded;
}
.T1-Body{
    background-color:  #f1f1f1;
}


