.ehed{
    background-color: #c4e5e4;

}
.Total{
     background-color: #d6eded;
     border-color: #c1c3c3 !important;
}
.T1-Body{
    background-color:  #f1f1f1;
}
