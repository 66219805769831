.mobel_box_grade{
  max-width: 75% !important;
}

.container {
  margin: 0 auto;
}

.school-info {
  text-align: center;
  line-height: 1.9;
}

.bottom-line {
  border-bottom: double;
}

.font-bold {
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
}
.certificate {
  margin-top: 30px;
  /* border: 1px solid #000; */
  padding: 20px;
}

.certificate-header {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  font-family: "Times New Roman", Times, serif;
  text-transform: uppercase;
}
.blank_line {
  border-bottom: double;
  display: inline;
}
.admission-no {
  border-bottom: 1px dotted black;
  font-weight: 500 !important;
}
.content1 {
  margin-top: 1rem;
}

.student-table > th {
  font-size: 0.8rem !important;
  font-weight: bold !important;
  color: black !important;
}
.t-bold{
  font-weight: bold !important;
  color: black !important;
  text-align: center;
}

.costom-header{
  background-color: #d6eded;
  color: white;
}

.costom-header2{
  background-color: #c4e5e4;
  color: white;
}

.costom-body{
  background-color: #f1f1f1;
}
.total-color{
  background-color: #d8ebe5;
}
.rank{
  background-color: #eee6d1;
}
.no-rank{
  background-color: red;
}