.hh{
        min-width: 300px;
}
.item{
    min-width:70px;
    min-height: 70px;
    background-color:#F46514;
    border-radius: 10px; /* adjust as needed */
    border: 1px solid #ccc; /* border color */
    padding: 10px; /* adjust as needed */
    box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.5);
    color: #ccc;
    font-size: 11px;
    font-weight: 900;
  
}
  .shadow-bottom {
    /* bottom shadow */
  }